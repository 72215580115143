<template>
  <div>
    <Loading :loading="loading" />

    <v-row v-if="model">
      <v-col cols="6">
        <!--  SUPPORTTICKETS  -->
        <v-card
          elevation="2"
          class="dashboard-support-card"
        >
          <v-card-title
            primary-title
            class="text-lg-h4"
          >
            <router-link :to="'/supportticket'" :class="{ 'white--text': $vuetify.theme.dark }">
              {{ $tc("dashboard.supportTicket.title", model.data.supportTicketCount) }}
            </router-link>
            <RelevantMarker v-if="$store.getters.showOnlyRelevantContent" />

            <div class="flex-grow-1" />
            <SupportTicketCreate
              :list-button="false"
              @saved="fetchData"
            />
          </v-card-title>
          <v-card-subtitle>
            {{ $t("dashboard.supportTicket.lead") }}
          </v-card-subtitle>
          <v-row
            class="charts"
            align="center"
          >
            <v-col
              v-for="chart in model.data.supportTicketCharts.charts"
              :key="chart.datasets[0].label"
              cols="4"
              class="chart"
            >
              <v-sheet @click="$router.push({ path: '/supportticket', query: { departmentId: chart.departmentId } })" style="cursor: pointer;">
                <span>{{ chart.title }}</span>
                <doughnut-chart
                  v-if="chart.count > 0"
                  :class="
                    'doughnut-container doughnut-' + chart.datasets[0].label
                  "
                  :path="'/supportticket'"
                  :chart-data="chart"
                  :legend-details="model.data.supportTicketCharts.legends"
                  style="
                    height: 210px;
                    width: 210px;
                    padding: 15px;
                    cursor: pointer;
                  "
                />
                <div
                  v-else
                  class="doughnut-container doughnut-empty"
                >
                  0
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row
            cols="auto"
            justify="center"
            align="center"
            class="legends"
          >
            <v-col />
            <v-col
              v-for="legend in model.data.supportTicketCharts.legends"
              :key="legend.Title"
              class="legend"
            >
              <router-link
                :to="{ path: '/supportticket', query: { statusId: legend.id } }"
              >
                <span class="legend-title" :class="{ 'white--text': $vuetify.theme.dark }"> {{ legend.title }}</span>
                <div
                  class="colorline"
                  :style="{ 'background-color': legend.color }"
                />
              </router-link>
            </v-col>
            <v-col />
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <!--  DELIVERIES  -->
        <v-card
          elevation="2"
          class="dashboard-deliveries-card"
        >
          <v-card-title
            primary-title
            class="text-lg-h4"
          >
            <router-link to="/delivery" :class="{ 'white--text': $vuetify.theme.dark }">
              {{ $tc("dashboard.delivery.title", model.data.deliveryCount) }}
            </router-link>
            <RelevantMarker v-if="$store.getters.showOnlyRelevantContent" />

            <div class="flex-grow-1" />
            <DeliveryCreate
              :list-button="false"
              @saved="fetchData"
            />
          </v-card-title>
          <v-card-subtitle>
            {{ $t("dashboard.delivery.lead") }}
          </v-card-subtitle>
          <v-row
            class="charts"
            align="center"
          >
            <v-col
              v-for="chart in model.data.deliveryCharts.charts"
              :key="chart.datasets[0].label"
              cols="4"
              class="chart"
            >
              <v-sheet @click="$router.push({ path: '/delivery', query: { departmentId: chart.departmentId } })" style="cursor: pointer;">
                <span>{{ chart.title }}</span>
                <doughnut-chart
                  v-if="chart.count > 0"
                  :class="
                    'doughnut-container doughnut-' + chart.datasets[0].label
                  "
                  :path="'/delivery'"
                  :chart-data="chart"
                  :legend-details="model.data.deliveryCharts.legends"
                />
                <div
                  v-else
                  class="doughnut-container doughnut-empty"
                >
                  0
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row
            cols="auto"
            justify="center"
            no-gutter
            align="center"
            class="legends"
          >
            <v-col />
            <v-col
              v-for="legend in model.data.deliveryCharts.legends"
              :key="legend.Title"
              class="legend"
            >
              <router-link
                :to="{ path: '/delivery', query: { statusId: legend.id } }"
              >
                <span class="legend-title" :class="{ 'white--text': $vuetify.theme.dark }"> {{ legend.title }}</span>
                <div
                  class="colorline"
                  :style="{ 'background-color': legend.color }"
                />
              </router-link>
            </v-col>
            <v-col />
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="model">
      <v-col cols="6">
        <ApprovalList
          :total-count="model.data.waitingApprovals.length"
          :items="model.data.waitingApprovals.slice(0, 5)"
        >
          <v-card-actions v-if="this.model.data.waitingApprovals.length > 5">
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="toggleShowAllApprovals()"
            >
              {{ $t("dashboard.showAll") }}
            </v-btn>
          </v-card-actions>
        </ApprovalList>
        <div class="flex-grow-1" />
        <v-dialog
          v-model="showAllApprovals"
          scrollable
          width="800px"
          persistent
        >
          <ApprovalList
            :totalCount="this.model.data.waitingApprovals.length"
            :items="this.model.data.waitingApprovals"
            titleClass="title white--text secondary mb-6"
          >
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="primary"
                @click="toggleShowAllApprovals()"
              >
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </ApprovalList>
        </v-dialog>
      </v-col>
      <v-col cols="6">
        <ActivityList
          :total-count="model.data.comingActivities.length"
          :items="model.data.comingActivities.slice(0, 5)"
        >
          <v-card-actions v-if="this.model.data.comingActivities.length > 5">
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="toggleShowAllActivities()"
            >
              {{ $t("dashboard.showAll") }}
            </v-btn>
          </v-card-actions>
        </ActivityList>
        <div class="flex-grow-1" />
        <v-dialog
          v-model="showAllActivities"
          scrollable
          width="800px"
          persistent
        >
          <ActivityList
            :totalCount="this.model.data.comingActivities.length"
            :items="this.model.data.comingActivities"
            titleClass="title white--text secondary mb-6"
          >
            <v-card-actions>
              <v-spacer />
              <v-btn
                outlined
                color="primary"
                @click="toggleShowAllActivities()"
              >
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </ActivityList>
        </v-dialog>
      </v-col>
    </v-row>

    <!-- Time registration has been disabled until v1.5.0 -->
    <!-- <v-row v-if="model">
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            {{
              $tc(
                "dashboard.timeRegistrations.title",
                model.data.timeRegistrations.length
              )
            }}
          </v-card-title>
          <v-card-text>
            <div
              v-bind:key="item.id"
              v-for="item in model.data.timeRegistrations"
            >
              <b
                ><span
                  >{{ item.createdOn | formatDate }} {{ item.detailCode }}
                  {{ item.detailTitle }}
                </span></b
              >
              <span v-if="item.locationId">@{{ item.locationTitle }} </span>
              <span v-bind:key="tag" v-for="tag in item.tags">#{{ tag }} </span>
              {{ item.personFullNameWithDetails }}
              {{ item.description }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DoughnutChart from "@/components/dashboard/Chart";
import SupportTicketCreate from "@/components/dashboard/SupportTicketCreate";
import DeliveryCreate from "@/components/dashboard/DeliveryCreate";
import ApprovalList from "@/components/dashboard/ApprovalList";
import ActivityList from "@/components/dashboard/ActivityList";
import Loading from "@/components/Loading";
import RelevantMarker from '@/components/RelevantMarker.vue';

export default {
  name: "Dashboard",
  components: {
    DoughnutChart,
    SupportTicketCreate,
    DeliveryCreate,
    ApprovalList,
    ActivityList,
    Loading,
    RelevantMarker
  },
  data() {
    return {
      model: null,
      loading: false,
      showAllApprovals: false,
      showAllActivities: false
    };
  },
  computed: {
    ...mapGetters([ 'showOnlyRelevantContent' ])
  },
  watch: {
    showOnlyRelevantContent() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.model = null;
      this.loading = true;

      try {
        const response = await this.$AuthService.api.get('dashboard');

        this.model = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    toggleShowAllApprovals() {
      this.showAllApprovals = !this.showAllApprovals;
    },

    toggleShowAllActivities() {
      this.showAllActivities = !this.showAllActivities;
    },
  }
};
</script>

<style scoped>
a,
a:hover {
  color: black;
  text-decoration: none;
}

div.doughnut-container {
  height: 210px;
  width: 210px;
  padding: 15px;
  cursor: pointer;
  margin: 0 auto;
  color: #808080;
}
div.doughnut-empty {
  font-size: 1.58em;
  padding-top: 85px;
}

div.legends {
  width: 100%;
}
div.legends .colorbox {
  width: 15px;
  height: 15px;
  display: inline-block;
  border: 1px solid white;
}
div.legends .colorline {
  width: 90px;
  height: 5px;
}
div.charts {
  text-align: center;
}
div.charts .chart {
  min-width: 210px;
}
</style>
<template>
  <v-card>
    <v-card-title
      primary-title
      :class="titleClass"
    >
      {{ $tc('dashboard.comingActivities.title', totalCount) }}

      <RelevantMarker v-if="$store.getters.showOnlyRelevantContent" />
    </v-card-title>
    <v-card-text>
      <div v-if="this.items.length > 0" class="dashboard-listcontainer">
        <v-row dense v-bind:key="item.id" v-for="item in this.items">
          <v-col cols="2">
            <router-link :to="getPath(item.deliveryId)">
              {{ item.endDate | formatDate }}
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link :to="getPath(item.deliveryId)">
              {{ item.deliveryCode }} {{ item.location }}
              <br>
              {{ item.deliveryTitle }}
            </router-link>
          </v-col>
          <v-col cols="4">
            <router-link :to="getPath(item.deliveryId)">
              {{ item.activity }} <span v-if="item.activityCount > 1"><br>({{ $tc('dashboard.comingActivities.total', item.activityCount) }})</span>
            </router-link>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        {{ $t('dashboard.comingActivities.noActivities') }}
      </div>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
import RelevantMarker from '@/components/RelevantMarker.vue';

export default {
  name: 'ActivityList',
  components: {
    RelevantMarker
  },
  props: ['totalCount', 'items', 'titleClass'],
  computed: {
    sortedItems() {
      return [...this.items].sort((a, b) => a.endDate.getTime() - b.endDate.getTime());
    }
  },
  methods: {
    getPath(deliveryId) {
      return `/delivery/${deliveryId}`;
    }
  }
};
</script>

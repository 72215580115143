<script>
import { Doughnut } from "vue-chartjs";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: Object,
      required: false,
    },
    legendDetails: {
      type: Array,
      required: false,
    },
    path: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.handle,
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            color: "#FFFFFF",
          },
        },
      },
    };
  },
  mounted() {
    this.addPlugin({
      id: "centertext-plugin",
      beforeDraw: this.centertext,
    });
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    centertext(chart) {
      var width = chart.chart.width;
      var height = chart.chart.height;
      var ctx = chart.chart.ctx;

      ctx.restore();
      var fontSize = (height / 114).toFixed(2);
      //console.log('fontSize'+fontSize);
      ctx.font = fontSize + "em sans-serif";
      ctx.fillStyle = "rgba(118,118,118,1)";
      ctx.textBaseline = "middle";

      var text = chart.data.count;
      var textX = Math.round((width - ctx.measureText(text).width) / 2);
      var textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
    handle(point, event) {
      let route =
        "/#" + this.path + "?departmentId=" + this.chartData.departmentId;

      if (event.length > 0) {
        route += "&statusId=" + this.legendDetails[event[0]._index].id;
      }

      if (point.ctrlKey) {
        window.open(route);
      } else {
        document.location.href = route;
      }
    },
  },
};

</script>
<template>
  <v-card>
    <v-card-title
      primary-title
      :class="titleClass"
    >
      {{ $tc("dashboard.waitingApprovals.title", totalCount) }}

      <RelevantMarker v-if="$store.getters.showOnlyRelevantContent" />
    </v-card-title>
    <v-card-text>
      <div v-if="this.items.length > 0" class="dashboard-listcontainer">
        <v-row dense v-bind:key="item.id" v-for="item in this.items">
          <v-col cols="2">
            <router-link :to="getPath(item.relationKind, item.relationId)">
              {{ item.deadline | formatDate }}
            </router-link>
          </v-col>
          <v-col cols="6">
            <router-link :to="getPath(item.relationKind, item.relationId)">
              {{ item.relationTitle }}
              <br>
              {{ item.relationDetail }}
            </router-link>
          </v-col>
          <v-col cols="4">
            <router-link :to="getPath(item.relationKind, item.relationId)">
              {{ item.typeTitle }}
            </router-link>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        {{ $t('dashboard.waitingApprovals.noApprovals') }}
      </div>
    </v-card-text>
    <slot />
  </v-card>
</template>

<script>
import RelevantMarker from '@/components/RelevantMarker.vue'

export default {
  name: 'ApprovalList',
  components: {
    RelevantMarker
  },
  props: ['totalCount', 'items', 'titleClass'],
  methods: {
    getPath(relationKind, relationId) {
      return `/${relationKind}/${relationId}`;
    }
  }
};
</script>
